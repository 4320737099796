@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 30px;
  height: 30px;
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #ea5a28; /* Black */
  border-bottom: 4px solid #59c4ef; /* Black */
  border-left: 4px solid gray; /* Black */
  border-radius: 50%;
  animation: spinner 0.4s linear infinite;
  margin: auto auto;
}
