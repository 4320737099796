.sheen-text {
  animation: colorChange 5s infinite;
}

@keyframes colorChange {
  0% {
    color: white;
  }

  50% {
    color: red;
  }

  100% {
    color: white;
  }
}

@keyframes fadeEffect {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
